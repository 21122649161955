import React from "react";
import "./feature.css";
const Features = () => {
  return (
    <section className="features__area">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-4">
          <div className="col-md-6 text-center">
            <h3 className="sub_heading">How We Start Journey</h3>
            <h1 className="main_heading">Start your Learning Journey Today!</h1>
            <p className="mt-3 feature_p">
              Discover a World of Knowledge and Skills at Your Fingertips –
              Unlock Your Potential and Achieve Your Dreams with Our
              Comprehensive Learning Resources!
            </p>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features__item">
              <div className="features__icon">
                <img
                  src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-11-05-27-50-9263.png"
                  alt="img"
                />
              </div>
              <div className="features__content">
                <h4 className="title">Learn with Experts</h4>
                <p>Elevate your learning. Trusted guidance, real results</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features__item">
              <div className="features__icon">
                <img
                  src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-11-05-49-32-6821.png"
                  alt="img"
                />
              </div>
              <div className="features__content">
                <h4 className="title">Learn Anything</h4>
                <p>
                  Master Any Skill. Unleash Your Potential and join the best
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features__item">
              <div className="features__icon">
                <img
                  src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-23-05-11-29-2802.png"
                  alt="img"
                />
              </div>
              <div className="features__content">
                <h4 className="title">Get Online Certificate</h4>
                <p>Master in Demand Skills. Soon Get Certified Today</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features__item">
              <div className="features__icon">
                <img
                  src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-11-05-27-50-7828.png"
                  alt="img"
                />
              </div>
              <div className="features__content">
                <h4 className="title">E-mail Marketing</h4>
                <p>Grow Your Business. Reach New Customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
