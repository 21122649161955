import React from "react";
import Slider from "react-slick";
import ProductCard from "../ProductCard/ProductCard";
import { Col, Row } from "react-bootstrap";

import "./topCourse.css";

const TopCourse = () => {
  const array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="course_section p-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 text-center">
              <h3 className="sub_heading">Top Class Courses</h3>
              <h1 className="main_heading">
                Explore Our Worlds Featured Courses
              </h1>
              <p className="mt-2">
                Check out the most demanding courses right now
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="course_slider mt-5">
                <Slider {...settings}>
                  {array?.map((item) => {
                    return (
                      <div className="item">
                        {" "}
                        <ProductCard />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopCourse;
