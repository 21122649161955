import React from "react";
import "./instructors.css";

const InstructorsItem = () => {
  return (
    <div class="instructor__item">
      <div class="instructor__thumb">
        <a href="#">
          <img
            src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-06-07-37-30-4324.png"
            alt="img"
          />
        </a>
      </div>
      <div class="instructor__content">
        <h2 class="title">
          <a href="#">Jason Thorne</a>
        </h2>
        <span class="designation">Developer</span>
        <p class="avg-rating">
          <i class="fas fa-star"></i>
          5.0 Ratings
        </p>
        <div class="instructor__social">
          <ul class="list-wrap">
            <li>
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-github"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InstructorsItem;
