import React from "react";
import AboutSec from "../../Components/aboutSec/AboutSec";
import HomeBanner from "../../Components/homeBanner/HomeBanner";

import Faq from "../../Components/faq/Faq";
import Categories from "../../Components/Categories/Categories";
import NewsLetter from "../../Components/NewsLetter/NewsLetter";
import Instructors from "../../Components/Instructors/Instructors";
import TopCourse from "../../Components/TopCourse/TopCourse";
import Features from "../../Components/Features/Features";
import Member from "../../Components/Member/Member";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <Categories />
      <AboutSec />
      <TopCourse />

      <NewsLetter />
      <Instructors />

      <Faq />
      <Features />
      <Member />
    </>
  );
};

export default Home;
