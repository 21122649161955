import React from "react";
import logo from "../../assets/image/logo_w.png";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="container">
          <div className="default-padding">
            <div className="row gy-5">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer_about wow animate__animated animate__fadeInLeft">
                  <img src={logo} alt="" />
                  {/* <p className="">
                    We offer a wide range of courses in various subjects, from
                    business and technology to art and personal development
                  </p> */}
                  <p>
                    Plot No.134/2/3, Bellandur, Near Embassy Tech Village,
                    Bangalore, Karnataka, 560035
                  </p>
                  <p>+1 (106) 882-8138</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer_link wow animate__animated animate__fadeInUp">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      {/* <a href="">Home</a> */}
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                      <Link to={"/course"}>Courses</Link>
                    </li>
                    {/* <li>
                      <a href="">Blog</a>

                    </li> */}
                    <li>
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer_link wow animate__animated animate__fadeInUp">
                  <h4>Our Company</h4>
                  <ul>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to={"/register"}>Become Teacher</Link>
                    </li>
                    <li>
                      <Link to={"/instructors"}>All Instructors</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer_address wow animate__animated animate__fadeInUp">
                  <h4>Get In Touch</h4>
                  <ul>
                    <li>
                      <i></i>
                      <p>
                        If you need any kind of help you can know us on socials
                        or mail us
                      </p>
                    </li>
                    <li>
                      <i></i>
                      <p>info@localhost</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow animate__animated animate__fadeInLeft">
              <p>© 2023-2024 heshvithatech.com. All rights reserved.</p>
            </div>
            <div className="col-md-6 wow animate__animated animate__fadeInRight">
              <ul>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
