import React from "react";
import img1 from "../../assets/image/about1.jpg";
import img2 from "../../assets/image/about2.jpg";
import "./aboutSec.css";
const AboutSec = () => {
  return (
    <>
      <div className="about-area p-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about__images">
                <img
                  src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-03-07-17-53-5562.png"
                  alt="img"
                  className="main-img"
                />
                <img
                  src="https://skillgro.websolutionus.com/frontend/img/others/about_shape.svg"
                  alt="img"
                  className="shape alltuchtopdown"
                />
                <a href="#" className="popup-video">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="28"
                    viewBox="0 0 22 28"
                    fill="none"
                  >
                    <path
                      d="M0.19043 26.3132V1.69421C0.190288 1.40603 0.245303 1.12259 0.350273 0.870694C0.455242 0.6188 0.606687 0.406797 0.79027 0.254768C0.973854 0.10274 1.1835 0.0157243 1.39936 0.00193865C1.61521 -0.011847 1.83014 0.0480663 2.02378 0.176003L20.4856 12.3292C20.6973 12.4694 20.8754 12.6856 20.9999 12.9535C21.1245 13.2214 21.1904 13.5304 21.1904 13.8456C21.1904 14.1608 21.1245 14.4697 20.9999 14.7376C20.8754 15.0055 20.6973 15.2217 20.4856 15.3619L2.02378 27.824C1.83056 27.9517 1.61615 28.0116 1.40076 27.9981C1.18536 27.9847 0.97607 27.8983 0.792638 27.7472C0.609205 27.596 0.457661 27.385 0.352299 27.1342C0.246938 26.8833 0.191236 26.6008 0.19043 26.3132Z"
                      fill="currentcolor"
                    ></path>
                  </svg>
                </a>
                <div className="about__enrolled">
                  <p className="title">
                    <span>80k</span>
                    Enrolled Students
                  </p>
                  <img
                    src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-03-09-44-49-4396.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 info">
              <div className="about__content">
                <div className="section__title">
                  <span className="sub_heading">Get More About Us</span>
                  <h2 className="main_heading">
                    Thousand Of Top <span className="highlight">Courses</span>
                    <br />
                    Now in One Place
                  </h2>
                </div>

                <p>
                  Discover a vast collection of top-rated courses, covering a
                  wide range of subjects and taught by expert instructors.
                  Whether you're looking to advance your career
                </p>
                <ul>
                  <li>The Most World Class Instructors</li>
                  <li>Access Your Class anywhere</li>
                  <li>Flexible Course Price</li>
                </ul>

                <div className="tg-button-wrap mt-4">
                  <a href="/about-us" className="btn_primary">
                    More Info{" "}
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-inject-url="https://skillgro.websolutionus.com/frontend/img/icons/right_arrow.svg"
                      className="injectable"
                    >
                      <path
                        d="M1 7L15 7M15 7L9 1M15 7L9 13"
                        stroke="currentcolor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M1 7L15 7M15 7L9 1M15 7L9 13"
                        stroke="currentcolor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSec;
