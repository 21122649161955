import React from "react";

import "./instructors.css";
import InstructorsItem from "./InstructorsItem";

const Instructors = ({ content = true }) => {
  let arr = [1, 1, 1, 1];
  return (
    <section class="instructor__area p-120">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-4">
            <div class="instructor__content-wrap">
              <div class="section__title mb-15">
                <span class="sub_heading">Skilled Introduce</span>
                <h2 class="main_heading">
                  Our Top Class &amp; Expert Instructors in One Place
                </h2>
              </div>
              <p>
                Combines the ideas of empowered learning and top-tier
                instruction for students. Emphasizes both instructor expertise
              </p>
              <div class="tg-button-wrap mt-4">
                <a href="/all-instructors" class="btn_primary">
                  See All Instructors{" "}
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    data-inject-url="https://skillgro.websolutionus.com/frontend/img/icons/right_arrow.svg"
                    class="injectable"
                  >
                    <path
                      d="M1 7L15 7M15 7L9 1M15 7L9 13"
                      stroke="currentcolor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M1 7L15 7M15 7L9 1M15 7L9 13"
                      stroke="currentcolor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="instructor__item-wrap">
              <div class="row">
                {arr.map((item) => {
                  return (
                    <div class="col-sm-6">
                      {" "}
                      <InstructorsItem />{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Instructors;
