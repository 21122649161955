import React from "react";
import pageBanner from "../../assets/image/pageBanner.png";
import "./breadcrumb.css";
const Breadcrumb = ({ name, pageName }) => {
  return (
    <>
      <div
        className=" breadcrumb__bg text-light"
        style={{
          backgroundImage: `url(https://skillgro.websolutionus.com/uploads/website-images/breadcrumb-image.jpg)`,
        }}
      >
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <div className="breadcrumb__content">
                <h3 className="title">{name}</h3>
                <nav className="breadcrumb">
                  <span property="itemListElement" typeof="ListItem">
                    <a href="/">Home</a>
                  </span>
                  <span className="breadcrumb-separator">
                    <i className="fas fa-angle-right"></i>
                  </span>
                  <span property="itemListElement" typeof="ListItem">
                    <a href="javascript:;">{pageName}</a>
                  </span>
                </nav>
              </div>
            </div>
            {/* <div className="col-lg-6 text-right"></div> */}
          </div>
        </div>

        <div class="breadcrumb__shape-wrap">
          <img
            src="https://skillgro.websolutionus.com/frontend/img/others/breadcrumb_shape01.svg"
            alt="img"
            class="alltuchtopdown"
          />
          <img
            src="https://skillgro.websolutionus.com/frontend/img/others/breadcrumb_shape02.svg"
            alt="img"
          />
          <img
            src="https://skillgro.websolutionus.com/frontend/img/others/breadcrumb_shape03.svg"
            alt="img"
          />
          <img
            src="https://skillgro.websolutionus.com/frontend/img/others/breadcrumb_shape04.svg"
            alt="img"
          />
          <img
            src="https://skillgro.websolutionus.com/frontend/img/others/breadcrumb_shape05.svg"
            alt="img"
          />
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
