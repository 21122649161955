import React from "react";
import "./testimonialCard.css";

const TestimonialCard = () => {
  return (
    <>
      <div class="testimonial__item">
        <div class="testimonial__item-top">
          <div class="testimonial__author">
            <div class="testimonial__author-thumb">
              <img
                src="https://skillgro.websolutionus.com/uploads/custom-images/wsus-img-2024-06-04-12-24-22-2171.png"
                alt="img"
              />
            </div>
            <div class="testimonial__author-content">
              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
              <h2 class="title">Emily S</h2>
            </div>
          </div>
        </div>
        <div class="testimonial__content">
          <p>
            “ I had the pleasure of taking a course on social media marketing
            taught by Instructor Their expertise in the field was evident and
            their passion ”
          </p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
